import { atom, useAtom } from 'jotai';

import { KeyMap } from 'types/keyboardShortcut';

const rundownView = {
  TOGGLE_READY: {
    label: 'Toggle Ready',
    sequences: ['mod+up'],
  },
  TOGGLE_PREPARING: {
    label: 'Toggle Preparing',
    sequences: ['mod+down'],
  },
  TOGGLE_ASSETS: {
    label: 'Toggle Assets',
    sequences: ['mod+left'],
  },
  TOGGLE_EDITOR: {
    label: 'Toggle Editor',
    sequences: ['mod+right'],
  },
  TOGGLE_ZEN_MODE_READY: {
    label: 'Toggle Zen Mode: Ready',
    sequences: ['mod+shift+up'],
  },
  TOGGLE_ZEN_MODE_PREPARING: {
    label: 'Toggle Zen Mode:  Preparing',
    sequences: ['mod+shift+down'],
  },
  TOGGLE_ZEN_MODE_ASSETS: {
    label: 'Toggle Zen Mode:  Assets',
    sequences: ['mod+shift+left'],
  },
  TOGGLE_ZEN_MODE_EDITOR: {
    label: 'Toggle Zen Mode:  Editor',
    sequences: ['mod+shift+right'],
  },
  INSERT_INSTANCE_ABOVE: {
    label: 'Insert instance above',
    sequences: ['mod+I'],
  },
  INSERT_INSTANCE_BELOW: {
    label: 'Insert instance below',
    sequences: ['mod+shift+I'],
  },
} satisfies Record<string, KeyMap>;

type RundownKeyboardShortcuts = keyof typeof rundownView;
export type RundownShortcuts = Record<RundownKeyboardShortcuts, KeyMap>;

const rundownAtom = atom<RundownShortcuts>(rundownView);

export const useRundownViewKeyMap = () => useAtom(rundownAtom);
