export const BACK_TIME_FIELD_KEY = 'default_backTime';
export const CUME_TIME_FIELD_KEY = 'default_cumeTime';
export const TOTAL_TIME_FIELD_KEY = 'default_totalDuration';
export const CLIP_TIME_FIELD_KEY = 'default_clipDuration';
export const SPEAK_TIME_FIELD_KEY = 'default_speakDuration';

export const TIMING_FIELDS = [
  BACK_TIME_FIELD_KEY,
  CUME_TIME_FIELD_KEY,
  TOTAL_TIME_FIELD_KEY,
  CLIP_TIME_FIELD_KEY,
  SPEAK_TIME_FIELD_KEY,
] as const;

export type TimingField = (typeof TIMING_FIELDS)[number];

export const isPointInTimeField: Record<string, boolean> = {
  [CUME_TIME_FIELD_KEY]: true,
  [BACK_TIME_FIELD_KEY]: true,
  [SPEAK_TIME_FIELD_KEY]: false,
  [CLIP_TIME_FIELD_KEY]: false,
  [TOTAL_TIME_FIELD_KEY]: false,
};

export const isMetadataPointInTimeField: Record<string, boolean> = {
  [`metadata.${CUME_TIME_FIELD_KEY}`]: true,
  [`metadata.${BACK_TIME_FIELD_KEY}`]: true,
  [`metadata.${SPEAK_TIME_FIELD_KEY}`]: false,
  [`metadata.${CLIP_TIME_FIELD_KEY}`]: false,
  [`metadata.${TOTAL_TIME_FIELD_KEY}`]: false,
};
