import { atom, useAtom } from 'jotai';

import { KeyMap } from 'types/keyboardShortcut';

import { isMac } from '../utils';

const rundownAction = {
  CREATE_INSTANCE: {
    label: 'Create Instance Below',
    tooltip: 'Create instance below the selection',
    sequences: ['mod+I'],
  },
  CREATE_INSTANCE_ABOVE: {
    label: 'Create Instance Above',
    tooltip: 'Create instance above the selection',
    sequences: ['mod+shift+I'],
  },
  CREATE_INSTANCE_PLACEHOLDER: {
    label: 'Create Placeholder Below',
    tooltip: 'Create instance placeholder below the selection',
    sequences: ['alt+I'],
  },
  CREATE_INSTANCE_PLACEHOLDER_ABOVE: {
    label: 'Create Placeholder Above',
    tooltip: 'Create instance placeholder above the selection',
    sequences: ['alt+shift+I'],
  },
  DELETE_INSTANCE: {
    label: 'Delete Instance',
    tooltip: 'Delete selected instance(s)',
    sequences: isMac ? ['mod+backspace'] : ['mod+backspace', 'delete'],
  },
  COPY_INSTANCE: {
    label: 'Copy Instance',
    tooltip: 'Copy selected instance(s)',
    sequences: ['mod+C'],
  },
  PASTE_INSTANCE: {
    label: 'Paste Instance Below',
    tooltip: 'Paste copied instance(s) below the selection',
    sequences: ['mod+V'],
  },
  PASTE_INSTANCE_ABOVE: {
    label: 'Paste Instance Above',
    tooltip: 'Paste copied instance(s) above the selection',
    sequences: ['mod+shift+V'],
  },
} satisfies Record<string, KeyMap>;

const rundownAtom = atom<Record<keyof typeof rundownAction, KeyMap>>(rundownAction);

export const useRundownActionKeyMap = () => useAtom(rundownAtom);
