import { memo } from 'react';

import Avatar from 'components/avatar/Avatar';
import Popover from 'components/dialogs/PopoverBuilder';
import Text from 'components/text/Text';
import { HStack, VStack } from 'layouts/box/Box';

import { BackIcon, StyledClose } from './styled';

interface HeaderProps {
  avatar: string;
  username: string;
  showQR: boolean;
  qrBackButtonPressed: () => void;
}

const QR_DIALOG_HEADER_TEXT = 'Saga Mobile login quick start';

function Header({ avatar, username, showQR, qrBackButtonPressed }: HeaderProps) {
  return (
    <VStack>
      <Popover.Close asChild>
        <StyledClose />
      </Popover.Close>
      {showQR ? (
        <HStack width="100%">
          <BackIcon onClick={qrBackButtonPressed} role="presentation" />
          <Text variant="h7">{QR_DIALOG_HEADER_TEXT}</Text>
        </HStack>
      ) : (
        <HStack height="56px" padding="0 8px" gap="12px" margin="8px">
          <Avatar size={48} imageKey={avatar} variant="user" />
          <Text variant="h5">{username}</Text>
        </HStack>
      )}
    </VStack>
  );
}

export default memo(Header);
