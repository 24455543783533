import { useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { atom, useAtom } from 'jotai';

import GET_RUNDOWN from 'operations/queries/getRundown';
import { QueryGetRundownArgs, Rundown } from 'types/graphqlTypes';

export const rundownTypes = {
  RUNDOWN: 'rundown',
  RUNDOWN_TEMPLATE: 'rundowntemplate',
} as const;

export type RundownMemberType = (typeof rundownTypes)[keyof typeof rundownTypes];

export interface AdjustedRundownType extends Omit<Rundown, 'mType'> {
  mId: string;
  mType: RundownMemberType;
  message?: string;
}
export type GetRundownReturnType = {
  getRundown: AdjustedRundownType;
};

type GetRundownInput = {
  mId: string;
};

/**
 * Initially this atom will contain the value that's coming from the master template.
 * For easy of development, we expose it like this so we can toggle back & forth for
 * quick testing reasons as well.
 */
const rundownV2Atom = atom<boolean>(false);
export const useRundownV2Atom = () => useAtom(rundownV2Atom);

const useGetRundown = ({ mId }: GetRundownInput) => {
  const [useV2, setUseV2] = useRundownV2Atom();
  const hasSetV2 = useRef(false);
  const { data, error, loading, refetch } = useQuery<GetRundownReturnType, QueryGetRundownArgs>(
    GET_RUNDOWN,
    {
      variables: {
        input: {
          mId: mId,
          mRefId: mId,
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  if (
    typeof data?.getRundown?.useV2 === 'boolean' &&
    data?.getRundown?.useV2 !== useV2 &&
    !hasSetV2.current
  ) {
    setUseV2(!!data?.getRundown.useV2);
    hasSetV2.current = true;
  }

  useEffect(() => {
    hasSetV2.current = false;
  }, [mId]);

  return { data, error, loading, refetch };
};

export default useGetRundown;
