/* eslint-disable import/no-extraneous-dependencies */
import { useState, useEffect, useCallback, useRef } from 'react';
import { useQuery } from '@apollo/client';
import GET_RUNDOWN_INSTANCES from 'operations/queries/getInstancesOfRundownOrder';
import NOTIFY_ASSET_UPDATE_SUBSCRIPTION from 'operations/subscriptions/notifyAssetUpdate';
import useApolloSubscription from 'hooks/useApolloSubscriptionV2';

import useBatchGetAssets from 'hooks/useBatchGetAssets';
import AssetTypes from 'utils/constants/assetTypes';

const useGetRundownAssets = (mId, assetsType = [AssetTypes.VIDEO, AssetTypes.IMAGE]) => {
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const ignoreCacheRef = useRef(true);
  const [batchGetAssets] = useBatchGetAssets();
  
  const { data } = useQuery(GET_RUNDOWN_INSTANCES, {
    variables: {
      input: {
        mId,
        mRefId: 'mOrder',
      },
    },
    fetchPolicy: 'network-only',
  });

  const getAssets = useCallback(async () => {
    if (!data?.rundownInstances) return;

    const assetsList = data.rundownInstances.reduce(
      (aList, { mId: instanceId, assets: instanceAssets }) => {
        if (!Array.isArray(instanceAssets)) return aList;

        const updatedAssets = instanceAssets.reduce((uList, uAsset) => {
          if (assetsType.includes(uAsset?.itemType) && uAsset?.mId && uAsset?.assetRefId)
            return [
              ...uList,
              { mId: uAsset.mId, mRefId: uAsset.assetRefId, instanceId, state: uAsset.state },
            ];
          return uList;
        }, []);

        return [...aList, ...updatedAssets];
      },
      [],
    );

    if (
      ignoreCacheRef.current ||
      assets?.length !== assetsList?.length ||
      !assets.every((assetItem, index) => {
        const assetListItem = assetsList[index];
        return (
          assetItem?.mId === assetListItem?.mId &&
          assetItem?.mRefId === assetListItem?.mRefId &&
          assetItem?.instanceId === assetListItem?.instanceId &&
          assetItem?.state === assetListItem?.state
        );
      })
    ) {
      setLoading(true);
      const queriedAssets = await batchGetAssets(assetsList, ignoreCacheRef.current);
      ignoreCacheRef.current = false;
      setAssets(queriedAssets);
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, assetsType, data]);

  useEffect(() => {
    getAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, assetsType]);

  useApolloSubscription(NOTIFY_ASSET_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: mId,
    },
    source: 'useGetRundownAssets',
    onSubscriptionData: ({ client, data: subscriptionData }) => {
      const { notifyAssetSubscription: updatedAsset } = subscriptionData.data;

      if (!assetsType.includes(uAsset?.itemType)) return;
      const updatedAssets = assets.map((a) => {
        if (a.mRefId !== updatedAsset.mRefId) return a;

        return {
          ...a,
          ...updatedAsset,
        };
      });

      setAssets(updatedAssets);
    },
  });

  return { assets, loading };
};

export default useGetRundownAssets;
