import PropTypes from 'prop-types';
import { useCurrentTabId } from 'store/tabs';
import useDinaNavigate from 'hooks/useDinaNavigate';
import { onImageLoadingError } from 'utils/image/imageUtils';
import useImageUrl from 'hooks/useImageUrl';
import useGetRundownTimings from 'hooks/useGetRundownTimings';
import { getLocalTimezone } from 'hooks/useTimezoneSettings';
import Icon from 'components/icon';

import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import ICONS from 'assets/icons/icons';
import { publishingPoints } from 'assets/icons/publishingPoints';
import { Button } from 'components/buttons';

import Tooltip from 'components/tooltip';
import {
  ListItem,
  ImageWrapper,
  Image,
  ContentWrapper,
  RundownTitle,
  TimingInfoWrapper,
  TimingInfo,
  TimingLabel,
  TimingValue,
  PlatformImage,
} from './listItem-styled';

const RundownContainer = ({
  id,
  title,
  plan,
  state,
  platformKind,
  selectedDates,
  setPreviewRundown,
  mThumbnailKey,
  timeZone,
  startTime,
  initializeRundown,
  rundowntype,
  tooltipTitle,
  showSelected,
  useV2,
}) => {
  const { navigateTo } = useDinaNavigate();
  const [currentTabId] = useCurrentTabId();
  const { getRundownPublishingTime } = useGetRundownTimings();
  const image = useImageUrl(mThumbnailKey);
  const activeTab = showSelected && id === currentTabId;
  const isAudio = platformKind === 'audio';
  const selectedDate = getRundownPublishingTime(
    selectedDates.startDate,
    startTime,
    timeZone ?? getLocalTimezone(),
  );

  const handleOpenRundown = () => {
    if (initializeRundown) {
      initializeRundown({ mTitle: title, mId: id, selectedDate });
      return;
    }

    navigateTo(rundowntype, id);
  };

  const handlePreviewRundown = (e) => {
    setPreviewRundown({
      id,
      date: selectedDate,
      image,
      title,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <ListItem button selected={activeTab} onClick={handleOpenRundown}>
      <ImageWrapper>
        <Image
          state={state}
          onError={onImageLoadingError}
          src={image ?? fallbackImage}
          alt="thumb"
        />

        {isAudio ? <PlatformImage src={publishingPoints.audio} alt="audio" /> : null}
      </ImageWrapper>
      <ContentWrapper>
        <Tooltip title={title}>
          <RundownTitle>{title}</RundownTitle>
        </Tooltip>
        <TimingInfoWrapper>
          <TimingInfo>
            <TimingLabel>IN</TimingLabel>
            <Tooltip title={tooltipTitle}>
              <TimingValue>{startTime}</TimingValue>
            </Tooltip>
          </TimingInfo>
          <TimingInfo>
            <TimingLabel>PLANNED</TimingLabel>
            <TimingValue>{plan}</TimingValue>
          </TimingInfo>
          {useV2 && (
            <TimingInfo>
              <TimingLabel>&nbsp;</TimingLabel>
              <TimingValue>V2</TimingValue>
            </TimingInfo>
          )}
        </TimingInfoWrapper>
      </ContentWrapper>
      {setPreviewRundown && (
        <Button
          title="Preview Rundown"
          onClick={handlePreviewRundown}
          variant="discreet"
          usage="text"
          size="icon"
        >
          <Icon icon={ICONS.DISCLOSURE_ARROW_RIGHT} />
        </Button>
      )}
    </ListItem>
  );
};

RundownContainer.propTypes = {
  /** Story id */
  id: PropTypes.string.isRequired,
  /** Story title */
  title: PropTypes.string.isRequired,
  /** The startTime in hh:mm:ss format  */
  startTime: PropTypes.string,
  /** plan time */
  plan: PropTypes.string,
  /** the state for the rundown (nostate,ready, active) */
  state: PropTypes.string,
  /** the type of the rundown (rundown or rundowntemplate) */
  // rundownType: PropTypes.string,
  /** set the previewRundown */
  setPreviewRundown: PropTypes.func,
  /** the selected dates */
  selectedDates: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  /** Start date of the rundown */
  startDate: PropTypes.string,
  /** show/hide blue-background(selected) basend on active tab */
  showSelected: PropTypes.bool,
};

RundownContainer.defaultProps = {
  startTime: '00:00:00',
  plan: '--:--:--',
  setPreviewRundown: undefined,
  selectedDates: { startDate: null, endDate: null },
  state: '',
  // rundownType: 'rundown',
  startDate: new Date().toISOString(),
  showSelected: false,
};

export default RundownContainer;
