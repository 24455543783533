import { useApolloClient } from '@apollo/client';
import GET_ASSET_FROM_CACHE from 'operations/queries/getAssetFromCache';
import BATCH_GET_ASSETS from 'operations/queries/batchGetAssets';
import { chunkArray } from 'utils/arrayUtils';

const useBatchGetAssets = () => {
  const client = useApolloClient();

  const batchGetAssets = async (assetsList, ignoreCache) => {
    const assetFetchInput = [];
    const AssetMap = new Map();
    const allMembersMap = new Map();

    const populateAssetMap = (asset) => {
      const { mId, mRefId } = asset || {};

      if (!mId || !mRefId) return;

      const assetDataInCache = client.readFragment({
        id: `${mId}:${mRefId}`,
        fragment: GET_ASSET_FROM_CACHE,
      });
      if (assetDataInCache && !ignoreCache) AssetMap.set(mRefId, { ...assetDataInCache, ...asset });
      else {
        AssetMap.set(`fetch-${mRefId}`, { ...asset });
        if (!assetFetchInput.find((a) => a.mRefId === mRefId)) {
          assetFetchInput.push({ mId, mRefId });
        }
      }
    };

    const getAsset = (inputAsset) => {
      const requestedAsset = AssetMap.get(`fetch-${inputAsset.mRefId}`);

      if (requestedAsset) {
        const DbAsset = allMembersMap.get(inputAsset.mRefId);
        DbAsset && AssetMap.set(inputAsset.mRefId, { ...requestedAsset, ...DbAsset });
        AssetMap.delete(`fetch-${inputAsset.mRefId}`);
      }

      return { ...AssetMap.get(inputAsset.mRefId), instanceId: inputAsset.instanceId };
    };

    assetsList.forEach(populateAssetMap);
    const chunkedInput = assetFetchInput.length ? chunkArray(assetFetchInput, 100) : [];

    const promises = chunkedInput.length
      ? chunkedInput.map((keys) =>
          client.query({
            query: BATCH_GET_ASSETS,
            variables: {
              input: { keys },
            },
            fetchPolicy: 'network-only',
          }),
        )
      : [];

    const responses = promises.length ? await Promise.all(promises) : null;

    responses &&
      Array.isArray(responses) &&
      responses.forEach((resp) =>
        resp?.data?.batchGetMembers?.forEach(
          (DbAsset) => DbAsset?.mRefId && allMembersMap.set(DbAsset.mRefId, { ...DbAsset }),
        ),
      );

    return assetsList.map(getAsset);
  };
  return [batchGetAssets];
};

export default useBatchGetAssets;
